import { arrowDown, arrowUp, check, circle, close, down, fileDoc, spinner, star, trash, } from "@formkit/icons"
import { generateClasses } from '@formkit/themes'
import { autocomplete, createProPlugin, dropdown, repeater} from '@formkit/pro'
import { createAutoAnimatePlugin, createFloatingLabelsPlugin } from '@formkit/addons'
import * as rules from "./rules";
import { createInput } from '@formkit/vue'
import theme from './theme.js'
import ContentInput from "@/components/ContentInput/ContentInput.vue";
import MediaInput from '@/components/MediaInput/MediaInput.vue'
import "@formkit/addons/css/floatingLabels";

const pro = createProPlugin('fk-7dc794d0af2', {
  autocomplete,
  dropdown,
  repeater,
})

const config = {
  config: {
    // pass our theme object to generateClasses
    classes: generateClasses(theme)
  },
  icons: {
    // include supporting icons from @formkit/icons
    arrowDown,
    arrowUp,
    close,
    checkboxDecorator: check,
    fileItem: fileDoc,
    fileRemove: close,
    noFiles: fileDoc,
    radioDecorator: circle,
    select: down,
    spinner,
    star,
    trash,
  },
  rules,
  plugins: [
    createAutoAnimatePlugin(),
    createFloatingLabelsPlugin({
      useAsDefault: true,
    }),
    pro],
  inputs: {
    content: createInput(ContentInput, {
      props: ['controls', 'sitePages'],
    }),
    mediaInput: createInput(MediaInput, {
      props: ['label', 'value', 'enableVideo', 'enableUnsplash', 'enableUploader', 'enableIcons', 'returnImagePathAsString', 'openAuthModal'],
    }),
  },
}

export default config
