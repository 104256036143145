<template>
  <div
    class="ring-1 ring-gray-400 focus-within:ring-brand-primary focus-within:ring-2 [&>label:first-child]:focus-within:text-brand-primary rounded mb-1 bg-white"
    data-testid="content-input"
    data-formkit-custom-input
    :name="context?.node?.name"
  >
    <div class="w-full">
      <Tiptap
        :value="context._value"
        :controls="context?.controls"
        :site-pages="context?.sitePages"
        @input="debouncedOnInput"
      />
    </div>
  </div>
</template>

<script setup>
import { debounce } from "lodash-es";
import { asyncComponentDefaults } from "@/composables";

const Tiptap = defineAsyncComponent({
  ...asyncComponentDefaults,
  loader: () => import("@/components/Tiptap/Tiptap.vue"),
});

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
});

// Methods
const onInput = (e) => {
  props?.context?.node?.input(e);
};

const debouncedOnInput = debounce(onInput, 100);
</script>
